<template>
  <v-dialog
    :value="value"
    max-width="300"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5">{{ title || $t('confirm_title') }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey"
          text
          :disabled="loading"
          @click="$emit('input', false)"
        >
          {{ cancel || $t('confirm_cancel') }}
        </v-btn>
        <v-btn
          color="success"
          text
          :loading="loading"
          @click="loading = true; $emit('ok')"
        >
          {{ ok || $t('confirm_ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDlg',
  props: {
    value: Boolean,
    title: String,
    text: String,
    ok: String,
    cancel: String
  },
  data () {
    return {
      loading: false
    }
  },
  watch: {
    value (value) {
      if (!value) {
        this.loading = false
      }
    }
  }
}
</script>
