<template>
  <div class="box mb-3">
    <div v-if="imgSrc">
      <img ref="img" :src="previewSrc" class="img" @load="onLoad"/>

      <div class="tools">
        <v-btn v-if="closable" icon class="mx-3" @click="$emit('close')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon class="mx-3" @click="remove">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>

    <div v-else>
      <div class="caption caption-label mb-1">{{ $t('img') }}</div>

      <div
        class="dropzone"
        :class="{ 'hover': isOver }"
        @dragover.stop.prevent="isOver = true"
        @dragenter.stop.prevent="isOver = true"
        @dragleave.stop.prevent="isOver = false"
        @dragend.stop.prevent="isOver = false"
        @drop.stop.prevent="onDrop"
      >
        <label :for="inputId + '_camera'">
          <v-icon x-large>mdi-camera</v-icon>
          <strong>{{ $t('camera') }}</strong>
        </label>

        <label :for="inputId + '_file'">
          <v-icon x-large>mdi-image-multiple</v-icon>
          <strong>{{ $t('gallery') }}</strong>
        </label>
        
        <input ref="camera" :id="inputId + '_camera'" type="file" accept="image/*" capture class="fileinput" @change="onCamera" />
        <input ref="file" :id="inputId + '_file'" type="file" accept="image/*" class="fileinput" @change="onFile" />
      </div>

      <div class="tools">
        <v-btn v-if="closable" icon class="mx-3" @click="$emit('close')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
    </div>

    <v-progress-linear v-if="uploading" :value="progress"/>
  </div>
</template>

<script>
import FileMixin from '@/mixins/FileMixin.js'

const IMAGE_FILE_SIZE_LIMIT = 10485760 // 10MB

export default {
  name: 'ImgUpload',
  mixins: [FileMixin],
  props: {
    value: Object,
    closable: Boolean
  },
  data () {
    return {
      imgSrc: null,
      imgW: 0,
      imgH: 0,
      imgB64: null,
      previewSrc: null,
      uploading: false,
      progress: 0,
      isOver: false,
      inputId: 'file_' + Math.random()
    }
  },
  methods: {
    onFile () {
      const file = this.$refs.file.files[0]
      this.uploadFile(file)
    },
    onCamera () {
      const file = this.$refs.camera.files[0]
      this.uploadFile(file)
    },
    onDrop (e) {
      this.isOver = false
      const file = e.dataTransfer.files[0]
      this.uploadFile(file)
    },
    uploadFile (file) {
      if (file.size > IMAGE_FILE_SIZE_LIMIT) {
        this.$root.snack = {
          text: this.$t('file_too_big', { limit: 10 }),
          color: 'error'
        }
        return
      }
      this.progress = 0
      this.uploading = true
      this.saveUploadedFile(file, p => { this.progress = p }).then(fileName => {
        this.imgSrc = fileName
        this.uploading = false
        this.input()
      })
    },
    onLoad () {
      this.imgW = this.$refs.img.naturalWidth
      this.imgH = this.$refs.img.naturalHeight
      this.input()
    },
    remove () {
      this.imgSrc = null
      this.input()
    },
    input () {
      if (this.imgSrc === this.value?.src) return
      if (this.imgSrc && this.imgW && this.imgH) {
        const val = {
          src: this.imgSrc,
          w: this.imgW,
          h: this.imgH
        }
        this.$emit('input', val)
      } else if (this.imgSrc === null) {
        this.$emit('input', null)
      }
    }
  },
  created () {
    if (this.value && this.value.src) {
      this.imgSrc = this.value.src
      this.imgW = this.value.w
      this.imgH = this.value.h
      this.imgB64 = this.value.b64
    }
  },
  watch: {
    value (value) {
      if (value === null) {
        this.imgSrc = null
        this.imgW = null
        this.imgH = null
        this.imgB64 = null
      }
    },
    imgSrc (imgSrc) {
      if (imgSrc) {
        this.getFileURL(imgSrc).then(src => {
          this.previewSrc = src
        })
      } else {
        this.previewSrc = null
      }
    }
  }
}
</script>

<style scoped>
.box {
  padding-bottom: 12px;
}
.label {
  font-size: 13px;
  color: rgba(0,0,0,0.54);
  padding-bottom: 6px;
  display: block;
}
.img {
  display: block;
  max-width: 100%;
  max-height: 33vh;
  margin: 0 auto;
}
.tools {
  width: 100%;
  background: #f5f5f5aa;
}
.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f080;
  outline: 2px dashed #aaa;
  box-shadow: 0 0 0 10px #f0f0f0;
  width: calc(100% - 20px);
  height: 170px;
  padding: 35px;
  margin: 20px 10px 10px;
  text-align: center;
}
.dropzone label {
  cursor: pointer;
  color: #888;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
}
.dropzone i {
  display: block;
  color: inherit;
  transition: none;
}
.hover {
  background-color: #c0c0c0;
  outline-color: #666;
  box-shadow: 0 0 0 10px #aaa;
}
.dropzone label:hover,
.hover label {
  color: #222;
}
.fileinput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>
